import { makeAutoObservable } from 'mobx'
import { InappParts, IPreview } from '~/dataStore/Campaign/Campaign.interface'
import {
  ChartDataType,
  IChartData,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { getGoalsRowsForNotification } from '~/pages/Journeys/Journey.service'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import { IInAppReportForReportService } from '../../Notification.interface'

const defaultInAppReport = {
  total: {
    send: {
      count: 0,
      graph: []
    },
    delivery: {
      count: 0,
      graph: []
    },
    dismiss: {
      count: 0,
      graph: []
    },
    bounced: {
      count: 0,
      graph: []
    },
    impressions: {
      count: 0,
      graph: []
    },
    buttonClickOne: {
      count: 0,
      graph: []
    },
    buttonClickTwo: {
      count: 0,
      graph: []
    }
  },
  unique: {
    send: {
      count: 0
    },
    delivery: {
      count: 0,
      rate: 0
    },
    bounced: {
      count: 0,
      rate: 0
    },
    impressions: {
      count: 0,
      rate: 0
    },
    clicks: {
      count: 0,
      rate: 0
    }
  }
}
export default class InAppReportReportService {
  constructor(
    public report: IInAppReportForReportService['inApp'] = defaultInAppReport,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report.unique

    return [
      {
        label: 'Sent',
        content: send.count ?? '- -',
        className: 'summary-field--green',
        tooltip: 'The total number of unique campaign sends.',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []

    const { delivery, impressions, bounced, clicks } = this.report.unique
    return [
      {
        label: 'Delivered',
        content: delivery.count,
        tooltip:
          'Total number of in-app notifications that were successfully delivered to a device.',
        rateLabel: 'Delivery Rate',
        rate: delivery.rate
      },
      {
        label: 'Impressions',
        content: impressions.count ?? '- -',
        tooltip: 'The total number of times the in-app message was shown',
        rate: impressions.rate,
        rateLabel: 'Impression Rate'
      },
      {
        label: 'Clicks',
        content: clicks.count ?? '- -',
        tooltip: 'Total number of clicks on buttons',
        rate: clicks.rate,
        rateLabel: 'Click Rate'
      },
      {
        label: 'Bounced',
        content: bounced?.count ?? '- -',
        tooltip:
          'The in-app notification will bounce if the permissions are disabled.',
        rate: bounced?.rate,
        rateLabel: 'Bounce Rate'
      }
    ]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    const {
      send,
      delivery,
      bounced,
      dismiss,
      buttonClickOne,
      buttonClickTwo,
      impressions
    } = this.report.total

    return [
      {
        name: 'Sent',
        valueCurrent: send.count,
        data: send.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Delivered',
        valueCurrent: delivery.count,
        data: delivery.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Bounced',
        valueCurrent: bounced.count,
        data: bounced.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Dismissed',
        valueCurrent: dismiss.count,
        data: dismiss.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickOne && {
        name: 'Button Click One',
        valueCurrent: buttonClickOne.count,
        data: buttonClickOne.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },

      buttonClickTwo && {
        name: 'Button Click Two',
        valueCurrent: buttonClickTwo.count,
        data: buttonClickTwo.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      // {
      //   name: 'Clicks',
      //   valueCurrent: clicks.count,
      //   data: clicks.graph?.slice() || [],
      //   dataType: ChartDataType.NUMERIC
      // },
      {
        name: 'Impressions',
        valueCurrent: impressions.count,
        data: impressions.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      }

      // impressions.uniqRateGraph && {
      //   name: 'Total Impressions Rate',
      //   valueCurrent: `${impression.totalUniqRate}%`,
      //   data: impression.uniqRateGraph.slice(),
      //   dataType: ChartDataType.PERCENTAGE
      // }
    ].filter(Boolean) as IChartData[]
  }

  public get goals(): IGoalPerformance[] {
    if (!this.report) {
      return []
    }

    return getGoalsRowsForNotification({
      name: MCampaignTypeToName.get(this.report.type) || '- -',
      goals: this.report.goals
    })
  }

  public get preview(): InappParts | undefined {
    if (!this.notificationPreview) return undefined

    return this.notificationPreview.inApp
  }
}
