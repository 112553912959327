import {
  CampaignStatus,
  CampaignType
} from '~/dataStore/Campaign/Campaign.interface'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { IGeneralBulkActions, ID } from '~/common.interface'

type DateString = string

type CampaignListActionKeys =
  | 'delete'
  | 'details'
  | 'duplicate'
  | 'edit'
  | 'exportData'
  | 'pause'
  | 'resume'
  | 'finish'

type CampaignTargets = 'beacon' | 'event' | 'geofence' | 'segment'

export type ICampaignListActions = {
  [x in CampaignListActionKeys]: boolean
}

export interface ICampaignListItemDTO {
  actions: ICampaignListActions
  createdAt: DateString
  groups: IGroup[]
  hidable: boolean
  hiddenFromFeed: boolean
  id: ID
  lastBuilderPage: string | null
  name: string
  status: CampaignStatus
  target: {
    [x in CampaignTargets]: boolean
  }
  type: string
  typeList: CampaignType[]
  isCampaign?: boolean
}

export type TChangeCampaignStatus = (
  campaign: ICampaignsListItem,
  { pause, resume }: { pause: boolean; resume: boolean },
  onUpdateRow: (row: ICampaignsListItem) => void,
  appId: ID,
  setSpinner?: (value: boolean) => void
) => void

export interface ICampaignsListItem extends ICampaignListItemDTO {
  batchActionUrls: IGeneralBulkActions & {
    resume: string
    pause: string
    hideFromFeed: string
  }
  pause: ({ id }: { id: ID }) => void
  resume: ({ id }: { id: ID }) => void
  disabled: boolean
  name: string
  isCampaign?: boolean
}

export const QUERY_PARAMS_DEFAULTS = {
  sort: 'created_at',
  order: 'desc',
  filterTypes: '',
  filterTargets: '',
  filterStatuses: '',
  groupIds: '',
  searchQuery: ''
}

export interface ICampaignsListQueryParams {
  sort: string
  order: string
  filterTypes: string
  filterTargets: string
  filterStatuses: string
  groupIds: string
  searchQuery: string
  page: number
  perPage: number
}
