import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Card, Container } from 'reactstrap'
import { getGroups } from '~/api/groups'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import Link from '~/components/Link'
import DeleteElementsModal from '~/components/modals/DeleteElementsModal'
import AddElementsToGroupModal from '~/components/Table/components/AddElementsToGroup'
import CreateGroupModal from '~/components/Table/components/CreateGroupModal'
import DeleteGroup from '~/components/Table/components/DeleteGroup'
import EditGroupModal from '~/components/Table/components/EditGroupModal'
import RemoveElementsFromGroupModal from '~/components/Table/components/RemoveElementsFromGroup'
import TableNew from '~/components/Table/TableNew'
import { useStore, withStore } from '~/dataStore'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import useQueryParams from '~/hooks/useQueryParams'
import useSelectListItems from '~/hooks/useSelectListItems'
import useSuggestedCampaigns from '~/pages/Campaign/CampaignsList/components/SuggestedCampaignsSidebar/useSuggestedCampaigns'
import routes from '~/routes'
import {
  ICampaignsListItem,
  QUERY_PARAMS_DEFAULTS
} from './CampaignsList.interface'
import './CampaignsList.scss'
import HideFromFeedModal from './components/HIdeFromFeedModal'
import SuggestedCampaignsBtn from './components/SuggestedCampaignsBtn'
import SuggestedCampaignsSidebar from './components/SuggestedCampaignsSidebar'
import tableColumns from './components/tableColumns'
import Toolbar from './components/Toolbar'
import useCampaigns, { useInvalidateCampaigns } from './useCampaigns'

const CampaignsList = () => {
  const { selectListItems, selectedListItems, clearSelectedItems } =
    useSelectListItems<ICampaignsListItem>()

  const {
    app: {
      currentAdmin,
      currentApp: { id: appId },
      appDetails: {
        featureFlags: { bannoPushSendToUrl }
      }
    },
    groups: {
      setGroups,
      groups,
      createGroup,
      isPerforming,
      editGroup,
      removeGroup,
      removeElementsFromGroup,
      addElementsToGroup
    }
  } = useStore()
  const paginationQuery = usePaginationQuery({ perPage: 50 })
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>()
  const invalidateCampaigns = useInvalidateCampaigns()
  const queryParams = useQueryParams(QUERY_PARAMS_DEFAULTS)

  const campaigns = useCampaigns({
    ...queryParams,
    ...paginationQuery.getPaginationQuery()
  })

  const { data: suggestedCampaigns } = useSuggestedCampaigns({
    sort: 'created_at',
    order: 'desc',
    filterTypes: '',
    filterTargets: '',
    filterStatuses: '',
    groupIds: '',
    searchQuery: '',
    page: 1,
    perPage: 13
  })

  useEffect(() => {
    getGroups(appId, 'campaigns').then((response) => setGroups(response.data))

    return () => {
      setGroups([])
    }
  }, [appId])

  return (
    <>
      <Container fluid>
        <Header className="d-flex justify-content-between align-items-end mt-0 pt-3 mb-4">
          <div className="d-flex">
            <HeaderTitle className="mb-0">
              <>
                Campaigns{' '}
                <span className="text-muted">
                  ({campaigns?.data?.metadata.dataCount})
                </span>
              </>
            </HeaderTitle>
          </div>
          <div className="d-flex align-items-center">
            {suggestedCampaigns?.pages.length > 0 && <SuggestedCampaignsBtn />}
            <SuggestedCampaignsSidebar />
            <Button
              tag={Link}
              route={routes.newCampaign}
              size="lg"
              color="primary"
              className="btn--wide btn--hover ms-3">
              <FontAwesomeIcon size="xs" icon={faPlus} className="me-2" /> New
              Campaign
            </Button>
          </div>
        </Header>
        <Card style={{ marginBottom: 350 }}>
          <Toolbar
            queryParams={queryParams}
            handleSearch={paginationQuery.handleSearch}
            searchQuery={paginationQuery.searchQuery}
            setSelectedCampaigns={clearSelectedItems}
            selectedCampaigns={selectedListItems}
            batchActionUrls={campaigns?.data?.bulkActions}
            setSelectedGroup={setSelectedGroup}
            isLoading={campaigns.isRefetching}
          />
          <div className="p-3">
            <TableNew
              onUpdateRow={invalidateCampaigns}
              isLoading={campaigns.isLoading}
              isLoadingMore={campaigns.isFetchingNextPage}
              columns={tableColumns}
              selectedRows={selectedListItems}
              onSelect={
                bannoPushSendToUrl || currentAdmin.isDemoAdmin
                  ? undefined
                  : selectListItems
              }
              hasMore={campaigns.hasNextPage}
              onLoadMore={() => campaigns.fetchNextPage()}
              rows={campaigns?.data?.pages || []}
              sortOrder={paginationQuery.order}
              sortColumn={paginationQuery.sortField}
              onSort={paginationQuery.handleSort}
            />
          </div>
        </Card>
      </Container>
      <DeleteElementsModal elementType="Campaign" nameKey="name">
        Deleting action may take up to 15 min
      </DeleteElementsModal>
      <CreateGroupModal
        groupType="campaigns"
        onSubmit={createGroup}
        isSaving={isPerforming}
      />
      <EditGroupModal
        group={selectedGroup}
        isSaving={isPerforming}
        onSubmit={editGroup}
      />
      <DeleteGroup
        onSubmit={(groupID) => {
          removeGroup(groupID)
          invalidateCampaigns()
          clearSelectedItems()
        }}
        queryParams={queryParams}
        group={selectedGroup}
        isSaving={isPerforming}
      />

      <AddElementsToGroupModal
        groups={groups}
        onSubmit={(groupId: string) =>
          addElementsToGroup({
            url: campaigns?.data?.bulkActions.addToGroup,
            groupId,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateCampaigns()
              clearSelectedItems()
            }
          })
        }
      />

      <RemoveElementsFromGroupModal
        onSubmit={(selectedGroups) => {
          if (!campaigns?.data?.bulkActions) return
          removeElementsFromGroup({
            selectedGroups,
            url: campaigns?.data?.bulkActions?.removeFromGroup,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateCampaigns()
              clearSelectedItems()
            }
          })
        }}
        nameKey="name"
        elements={Array.from(selectedListItems, ([, value]) => value)}
        elementsName="Campaigns"
        isSaving={isPerforming}
      />
      <HideFromFeedModal />
    </>
  )
}

export default withStore(CampaignsList)
