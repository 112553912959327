import { makeAutoObservable } from 'mobx'
import { IPreview, PreviewParts } from '~/dataStore/Campaign/Campaign.interface'
import {
  ChartDataType,
  IChartData,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { getGoalsRowsForNotification } from '~/pages/Journeys/Journey.service'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import { IPushReportForReportService } from '../../Notification.interface'

const defaultPushReport = {
  total: {
    send: {
      count: 0,
      graph: []
    },
    delivery: {
      count: 0,
      graph: []
    },
    dismiss: {
      count: 0,
      graph: []
    },
    bounced: {
      count: 0,
      graph: []
    },
    click: {
      count: 0,
      graph: []
    },
    buttonClickOne: {
      count: 0,
      graph: []
    },
    buttonClickTwo: {
      count: 0,
      graph: []
    }
  },
  unique: {
    send: {
      count: 0
    },
    delivery: {
      count: 0,
      rate: 0
    },
    pendingDelivery: {
      count: 0,
      rate: 0
    },
    bounced: {
      count: 0,
      rate: 0
    },
    clicks: {
      count: 0,
      rate: 0
    }
  }
}
export default class PushReportReportService {
  public variant = 0

  public isAlert: undefined | boolean = false

  constructor(
    public report: IPushReportForReportService['push'] = defaultPushReport,
    isAlert?: boolean,
    variant?: number,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
    this.variant = variant ?? 0
    this.isAlert = isAlert
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report.unique

    return [
      {
        label: 'Sent',
        content: send.count ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'The total number of push notifications that were sent to users who opted into push app notifications with an active device.',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []
    if (this.isAlert) {
      return [
        { label: 'Delivered', disabled: true },
        { label: 'Pending Delivery', disabled: true },
        { label: 'Clicked', disabled: true },
        { label: 'Bounced', disabled: true }
      ]
    }

    const { delivery, pendingDelivery, clicks, bounced } = this.report.unique
    return [
      {
        label: 'Delivered',
        content: delivery.count,
        tooltip:
          'Total number of push notifications successfully delivered to the target audience.',
        rateLabel: 'Delivery Rate',
        rate: delivery.rate,
        disabled: this.isAlert
      },
      {
        label: 'Pending Delivery',
        content: pendingDelivery?.count ?? '- -',
        tooltip:
          'Total number of push notifications that were delivered yet to receive system confirmation. Confirmations can take up to some time to receive; please contact your Pulsate partner for more information.',
        rate: pendingDelivery?.rate,
        rateLabel: 'Pending Delivery Rate',
        disabled: this.isAlert
      },
      {
        label: 'Clicked',
        content: clicks?.count ?? '- -',
        tooltip:
          'The total number of push notifications that were delivered and clicked.',
        rate: clicks?.rate,
        rateLabel: 'Click Rate',
        disabled: this.isAlert
      },
      {
        label: 'Bounced',
        content: bounced?.rate ?? '- -',
        tooltip:
          'Total number of push notifications that were delivered but bounced because device push permissions were disabled.',
        rate: bounced?.rate,
        rateLabel: 'Bounce Rate',
        disabled: this.isAlert
      }
    ]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    if (this.isAlert) {
      return [{ name: 'Sent', valueCurrent: '' }]
    }

    const {
      send,
      delivery,
      bounced,
      dismiss,
      click,
      buttonClickOne,
      buttonClickTwo
    } = this.report.total

    return [
      {
        name: 'Sent',
        valueCurrent: send.count,
        data: send.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Delivered',
        valueCurrent: delivery.count,
        data: delivery.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      bounced && {
        name: 'Bounced',
        valueCurrent: bounced.count,
        data: bounced.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Dismissed',
        valueCurrent: dismiss.count,
        data: dismiss.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickOne && {
        name: 'Button Click One',
        valueCurrent: buttonClickOne.count,
        data: buttonClickOne.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickTwo && {
        name: 'Button Click Two',
        valueCurrent: buttonClickTwo.count,
        data: buttonClickTwo.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Push Clicks',
        valueCurrent: click.count,
        data: click.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      }
      // {
      //   name: 'Total Clicks',
      //   valueCurrent: click.count,
      //   data: click.graph?.slice() || [],
      //   dataType: ChartDataType.NUMERIC
      // }
      // engagement?.uniqRateGraph && {
      //   name: 'Click Rate',
      //   valueCurrent: `${engagement.rate}%`,
      //   data: engagement.uniqRateGraph?.slice() || [],
      //   dataType: ChartDataType.PERCENTAGE
      // }
    ].filter(Boolean) as IChartData[]
  }

  public get goals(): IGoalPerformance[] {
    if (!this.report) {
      return []
    }

    return getGoalsRowsForNotification({
      name: MCampaignTypeToName.get(this.report.type) || '- -',
      goals: this.report.goals
    })
  }

  public get preview(): PreviewParts | undefined {
    if (!this.notificationPreview) return undefined

    return this.notificationPreview.push.variants[this.variant]
  }
}
