import { reject } from 'lodash'
import React from 'react'
import { Card, Container, Spinner } from 'reactstrap'
import StatusOption from '~/components/Filters/StatusOption'
import MultiSelect from '~/components/forms/MultiSelect'
import SearchInput from '~/components/forms/SearchInput'
import TableHeader from '~/components/Table/TableHeader'
import TableNew from '~/components/Table/TableNew'
import { useStore } from '~/dataStore'
import {
  CampaignStatus,
  CampaignType
} from '~/dataStore/Campaign/Campaign.interface'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import useQueryParams from '~/hooks/useQueryParams'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import tableColumns from './campaignReportListColumn'
import useCampaignReports from './useCampaignReports'

export default function CampaignReportList(): React.ReactElement {
  const {
    app: { currentApp }
  } = useStore()

  const paginationQuery = usePaginationQuery({ perPage: 20 })
  const queryParams = useQueryParams({
    sort: 'created_at',
    order: 'desc',
    filterTypes: '',
    filterStatuses: '',
    searchQuery: ''
  })

  const {
    data: campaigns,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isLoading
  } = useCampaignReports(currentApp.id, {
    ...queryParams,
    ...paginationQuery.getPaginationQuery()
  })

  const handleFilterType = (values) => {
    queryParams.filterTypes = values.join(',')
    paginationQuery.setPage(1)
  }

  const handleFilterStatuses = (values) => {
    queryParams.filterStatuses = values.join(',')
    paginationQuery.setPage(1)
  }

  return (
    <Container fluid>
      <TableHeader>
        <>
          Campaign Stats{' '}
          {campaigns?.metadata && (
            <span className="text-muted">
              ({campaigns?.metadata.dataCount})
            </span>
          )}
        </>
      </TableHeader>
      <Card>
        <div className="d-flex justify-content-space-between align-items-center p-3">
          <SearchInput
            placeholder="Search for a campaign"
            initialValue={paginationQuery.searchQuery}
            onChange={paginationQuery.handleSearch}
            className="me-3 flex-0-1"
          />

          <MultiSelect
            title="Type"
            selectedOptions={reject(
              queryParams.filterTypes?.split(','),
              (s) => s === ''
            )}
            onSelect={handleFilterType}
            resetOptionName="Show All"
            options={[
              {
                name: MCampaignTypeToName.get(CampaignType.PUSH),
                value: 'push_notification'
              },
              {
                name: MCampaignTypeToName.get(CampaignType.IN_APP),
                value: 'in_app_notification'
              },
              {
                name: MCampaignTypeToName.get(CampaignType.CARD),
                value: 'card'
              },
              {
                name: MCampaignTypeToName.get(CampaignType.SMS),
                value: 'sms_notification'
              },
              {
                name: MCampaignTypeToName.get(CampaignType.EMAIL),
                value: 'email_notification'
              }
            ]}
            className="me-3"
          />

          <MultiSelect
            title="Status"
            selectedOptions={reject(
              queryParams.filterStatuses?.split(','),
              (s) => s === ''
            )}
            onSelect={handleFilterStatuses}
            resetOptionName="Show All"
            options={[
              {
                name: (
                  <StatusOption name="Active" status={CampaignStatus.ACTIVE} />
                ),
                value: 'active'
              },
              {
                name: (
                  <StatusOption
                    name="Scheduled"
                    status={CampaignStatus.SCHEDULED}
                  />
                ),
                value: 'scheduled'
              },
              {
                name: (
                  <StatusOption name="Paused" status={CampaignStatus.PAUSED} />
                ),
                value: 'paused'
              },
              {
                name: (
                  <StatusOption
                    name="Delivered"
                    status={CampaignStatus.DELIVERED}
                  />
                ),
                value: 'delivered'
              }
            ]}
            className="me-3"
          />
          {isRefetching && (
            <Spinner color="primary" type="grow" className="ms-auto" />
          )}
        </div>
        <div className="p-3">
          <TableNew
            columns={tableColumns}
            hasMore={hasNextPage}
            onLoadMore={() => fetchNextPage()}
            isLoading={isLoading}
            isLoadingMore={isFetchingNextPage}
            rows={campaigns?.pages || []}
            sortOrder={paginationQuery.order}
            sortColumn={paginationQuery.sortField}
            onSort={paginationQuery.handleSort}
          />
        </div>
      </Card>
    </Container>
  )
}
