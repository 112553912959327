import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { fetchCampaign, fetchCampaignPreview } from '~/api/campaigns'
import {
  CampaignType,
  IPreview,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import {
  getCampaignTypeOptions,
  getVariantNumber
} from '~/dataStore/CampaignTypeFacade'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { IStore } from '~/dataStore/Store.interface'
import { formatToTimeZone } from '~/helpers/deliverTimeWindows'
import { generateDisplayType } from '~/pages/Campaign/Notification/Notification.service'
import CardReportCombined from '~/pages/Campaign/Notification/Report/Model/CardReport'
import EmailReportCombined from '~/pages/Campaign/Notification/Report/Model/EmailReport'
import InAppReportCombined from '~/pages/Campaign/Notification/Report/Model/InAppReport'
import PushReportCombined from '~/pages/Campaign/Notification/Report/Model/PushReport'
import SMSReportCombined from '~/pages/Campaign/Notification/Report/Model/SMSReport'
import CardReportReportService from '../../Notification/Report/Model/CardReportReportService'
import InAppReportReportService from '../../Notification/Report/Model/InAppReportReportService'
import PushReportReportService from '../../Notification/Report/Model/PushReportReportService'
import { fetchMixGoalsPerformance, fetchMixStats } from './Report.connector'
import {
  ChartDataType,
  IChartData,
  IGoalsPerformanceRow,
  ILinkReport,
  IReport,
  IReportModel,
  ISummary
} from './Report.interface'
export default class Report implements IReportModel {
  currentReport:
    | PushReportCombined
    | EmailReportCombined
    | CardReportCombined
    | InAppReportCombined
    | InAppReportReportService
    | PushReportReportService
    | CardReportReportService
    | SMSReportCombined
    | undefined = undefined

  report: IReport | undefined = undefined

  currentType: CampaignType | undefined = undefined

  startDateFilter: Date | null = null

  endDateFilter: Date | null = null

  rootStore: IStore

  constructor(rootStore: IStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public async fetchReport(appId: string, campaignId: string): Promise<void> {
    try {
      const [details, preview] = await Promise.all([
        fetchCampaign<IReport>(appId, campaignId, {
          stats: true
        }),
        fetchCampaignPreview(appId, campaignId)
      ])
      details.displayType = generateDisplayType(
        details.typeList || [],
        !!details.cardNotification?.backParts
      )
      this.initReport(details, preview)

      await this.fetchStatsAndGoals(appId, campaignId)

      this.initDateFilter()
    } catch (error) {
      console.error(error)
    }
  }

  public initReport(details: IReport, preview: IPreview): void {
    this.report = { ...details, preview, controlGroupParsed: null }
    if (
      this.report.controlGroup != null &&
      this.rootStore?.app.appDetails.featureFlags.campaignsGoals
    ) {
      this.report.controlGroupParsed = `${this.report.controlGroup}%`
    }
  }

  public initDateFilter(): void {
    if (!this.report) {
      return
    }
    if (!this.startDateFilter) {
      this.startDateFilter = formatToTimeZone(
        this.report.startAt,
        this.report.timeZoneName
      )
      this.endDateFilter = this.report.endAt
        ? formatToTimeZone(this.report.endAt, this.report.timeZoneName)
        : new Date()
    }
  }

  public async fetchStatsAndGoals(
    appId: string,
    campaignId: string
  ): Promise<void> {
    if (!this.report) {
      return
    }

    try {
      const query = this.prepareQuery()
      const [stats, goals] = await Promise.all([
        fetchMixStats(appId, campaignId, query),
        fetchMixGoalsPerformance(appId, campaignId, query)
      ])

      this.report.stats = stats
      this.report.primaryGoal = goals.primaryGoal
      this.report.secondaryGoal = goals.secondaryGoal

      this.setType(this.currentType || this.campaignTypeOptions[0]?.value)
    } catch (error) {
      console.error(error)
    }
  }

  private prepareQuery(): { startAt?: string; endAt?: string } {
    const query: { startAt?: string; endAt?: string } = {}
    if (this.startDateFilter) {
      query.startAt = moment(this.startDateFilter).format('YYYY-MM-DD')
    }

    if (this.endDateFilter) {
      query.endAt = moment(this.endDateFilter).format('YYYY-MM-DD')
    }

    return query
  }

  public get campaignTypeOptions(): (IOption & {
    original: CampaignType
  })[] {
    if (this.report?.typeList) {
      return getCampaignTypeOptions(this.report.typeList, this.report.preview)
    }

    return []
  }

  public setType(type: CampaignType | undefined): void {
    this.currentType = type

    this.createReport()
  }

  public setFilterDates({
    startDate,
    endDate
  }: {
    startDate: Date
    endDate: Date
  }): void {
    this.startDateFilter = startDate
    this.endDateFilter = endDate
  }

  private createReport(): void {
    let mappedType = this.currentType
    let variant = 0

    if (this.currentType?.includes('push')) {
      mappedType = CampaignType.PUSH
      variant = getVariantNumber(this.currentType)
    }

    this.combinedReport(mappedType, variant)
  }

  private combinedReport(
    type: CampaignType | string | undefined,
    variant: number
  ): void {
    if (!this.report?.stats) {
      return
    }

    const { stats } = this.report

    switch (type) {
      case CampaignType.CARD:
      case CampaignType.CARD_FRONT:
      case CampaignType.CARD_BACK:
        if (
          this.rootStore?.app.appDetails.featureFlags.infraReportServiceRead
        ) {
          this.currentReport = new CardReportReportService(
            stats.card,
            type,
            this.report?.controlGroupParsed,
            this.report?.preview
          )
        } else {
          this.currentReport = new CardReportCombined(
            stats.card,
            type,
            this.report?.controlGroupParsed,
            this.report?.preview
          )
        }
        break
      case CampaignType.EMAIL:
        this.currentReport = new EmailReportCombined(
          stats.email,
          this.report?.controlGroupParsed,
          {
            email: {
              ...this.report?.preview?.email,
              html: this.report.preview?.email.notification.html
            }
          }
        )
        break
      case CampaignType.IN_APP:
        if (
          this.rootStore?.app.appDetails.featureFlags.infraReportServiceRead
        ) {
          this.currentReport = new InAppReportReportService(
            stats.inApp,
            this.report?.controlGroupParsed,
            this.report?.preview
          )
        } else {
          this.currentReport = new InAppReportCombined(
            stats.inApp,
            this.report?.controlGroupParsed,
            this.report?.preview
          )
        }
        break
      // Uncomment when supported
      // case CampaignType.ALERT:
      //   this.currentReport = new PushReportCombined(
      //     stats.alert,
      //     false,
      //     variant,
      //     undefined,
      //     this.report?.preview
      //   )
      //   break
      case CampaignType.PUSH:
        if (
          this.rootStore?.app.appDetails.featureFlags.infraReportServiceRead
        ) {
          this.currentReport = new PushReportReportService(
            stats.push,
            false,
            variant,
            this.report?.controlGroupParsed,
            this.report?.preview
          )
        } else {
          this.currentReport = new PushReportCombined(
            stats.push,
            false,
            variant,
            this.report?.controlGroupParsed,
            this.report?.preview
          )
        }
        break
      case CampaignType.SMS:
        this.currentReport = new SMSReportCombined(
          stats.sms,
          this.report?.controlGroupParsed,
          this.report?.preview
        )
        break
      default:
        this.currentReport = undefined
        break
    }
  }

  public get summary(): ISummary[] {
    if (!this.report)
      return [
        {
          label: 'Start Date'
        },
        {
          label: 'End Date'
        },
        {
          label: 'Time Zone'
        },
        {
          label: 'Campaign Type'
        },
        {
          label: 'Target'
        },
        ...(this.rootStore?.app.appDetails.featureFlags.campaignsGoals
          ? [
              {
                label: 'Primary Goal'
              },
              {
                label: 'Secondary Goal'
              }
            ]
          : [])
      ]

    return [
      {
        label: 'Start Date',
        content: moment
          .tz(this.report.startAt, this.report.timeZoneName)
          .format('HH:mma, MMM DD YYYY')
      },
      {
        label: 'End Date',
        content: this.report.endAt
          ? moment
              .tz(this.report.endAt, this.report.timeZoneName)
              .format('HH:mma, MMM DD YYYY')
          : '- -'
      },
      {
        label: 'Time Zone',
        content: this.report.timeZoneName
      },
      {
        label: 'Campaign Type',
        content: this.report.displayType
      },
      this.report.typeList?.includes(CampaignType.EMAIL) && {
        label: 'From Email',
        content: this.report.preview.email.notification?.emailAddress || '- -',
        className: 'summary-field--truncate'
      },
      this.report.typeList?.includes(CampaignType.EMAIL) && {
        label: 'From Name',
        content: this.report.preview.email.notification?.fromName || '- -'
      },
      {
        label: 'Last Edited By',
        content: this.report.authorName,
        className: 'summary-field--truncate'
      },
      // this.report.stats?.sdk && {
      //   label: 'Old Data / New Data',
      //   content: `${this.report.stats.sdk.old}% / ${this.report.stats.sdk.new}%`,
      //   tooltip:
      //     'Data gathered by SDKs equal or higher then Android 3.7.0 / iOS 2.24.0. We can provide much more valuable infromation, but we need you to upgrade to the newest SDK in your mobile App to gather sufficinet data.'
      // },
      ...(this.rootStore?.app.appDetails.featureFlags.campaignsGoals
        ? [
            {
              label: 'Primary Goal',
              content:
                this.report.goalsAttributes?.find((g) => g.primary)
                  ?.eventIdentifier ||
                this.report.goalsAttributes?.find((g) => g.primary)
                  ?.eventKind ||
                '- -'
            },
            {
              label: 'Secondary Goal',
              content:
                this.report.goalsAttributes?.find((g) => !g.primary)
                  ?.eventIdentifier ||
                this.report.goalsAttributes?.find((g) => !g.primary)
                  ?.eventKind ||
                '- -'
            }
          ]
        : [])
    ].filter(Boolean) as ISummary[]
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.currentReport)
      return [
        {
          label: 'Sent',
          className: 'summary-field--green',
          tooltip:
            'Total number of unique campaign sends, minus those who previously unsubscribed.',
          icon: 'icon--sent'
        },
        ...(this.rootStore?.app.appDetails.featureFlags.campaignsGoals
          ? [
              {
                label: 'Control Group',
                className: 'summary-field--green',
                tooltip:
                  'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
                icon: 'icon--control-group'
              }
            ]
          : [])
      ]

    return this.currentReport.summaryNumbers
  }

  public get numbers(): ISummary[] {
    if (!this.currentReport)
      return [
        { label: 'placeholder1' },
        { label: 'placeholder2' },
        { label: 'placeholder3' },
        { label: 'placeholder4' }
      ]

    return this.currentReport.numbers
  }

  public get charts(): IChartData[] {
    if (!this.currentReport)
      return [
        {
          name: 'Sent',
          data: [],
          dataType: ChartDataType.NUMERIC
        },
        {
          name: 'Delivered',
          data: [],
          dataType: ChartDataType.NUMERIC
        }
      ]

    return this.currentReport.charts
  }

  public get goalsPerformance(): {
    primary: IGoalsPerformanceRow[]
    secondary: IGoalsPerformanceRow[]
  } {
    if (!this.report)
      return {
        primary: [],
        secondary: []
      }

    return {
      primary: [
        {
          name: this.report.primaryGoal?.name || '- - ',
          conversionRate: this.report.primaryGoal?.conversionRate ?? '- -',
          conversionDiff: this.report.primaryGoal?.conversionDiff ?? '- -',
          revenueRate: this.report.primaryGoal?.revenueRate ?? '- -',
          revenueDiff: this.report.primaryGoal?.revenueDiff ?? '- -'
        },
        {
          name: 'Control Group',
          revenueRate: '',
          conversionRate:
            this.report.primaryGoal?.controlGroupConversionRate ?? '- -'
        }
      ],
      secondary: [
        {
          name: this.report.secondaryGoal?.name || '- -',
          conversionRate: this.report.secondaryGoal?.conversionRate ?? '- -',
          conversionDiff: this.report.secondaryGoal?.conversionDiff ?? '- -',
          revenueRate: this.report.secondaryGoal?.revenueRate ?? '- -',
          revenueDiff: this.report.secondaryGoal?.revenueDiff ?? '- -'
        },
        {
          name: 'Control Group',
          revenueRate: '',
          conversionRate:
            this.report.secondaryGoal?.controlGroupConversionRate ?? '- -'
        }
      ]
    }
  }

  public get clickPerformance(): ILinkReport[] | undefined {
    if (!this.currentReport || !('clickPerformance' in this.currentReport))
      return undefined

    return this.currentReport.clickPerformance
  }

  public get preview(): IPreviewParts | undefined {
    if (!this.currentReport) return undefined

    return this.currentReport.preview
  }
}
