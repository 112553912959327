import { makeAutoObservable } from 'mobx'
import {
  CampaignType,
  CardParts,
  IPreview
} from '~/dataStore/Campaign/Campaign.interface'
import {
  ChartDataType,
  IChartData,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { getGoalsRowsForNotification } from '~/pages/Journeys/Journey.service'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import { ICardReportForReportService } from '../../Notification.interface'

const defaultCardReport = {
  total: {
    send: {
      count: 0,
      graph: []
    },
    delivery: {
      count: 0,
      graph: []
    },
    deleted: {
      count: 0,
      graph: []
    },
    frontImpressions: {
      count: 0,
      graph: []
    },
    frontButtonClickOne: {
      count: 0,
      graph: []
    },
    frontButtonClickTwo: {
      count: 0,
      graph: []
    },
    backImpressions: {
      count: 0,
      graph: []
    },
    backButtonClickOne: {
      count: 0,
      graph: []
    },
    backButtonClickTwo: {
      count: 0,
      graph: []
    }
  },
  unique: {
    send: {
      count: 0
    },
    delivery: {
      count: 0,
      rate: 0
    },
    deleted: {
      count: 0,
      rate: 0
    },
    frontImpressions: {
      count: 0,
      rate: 0
    },
    backImpressions: {
      count: 0,
      rate: 0
    },
    clicks: {
      count: 0,
      rate: 0
    }
  }
}

export default class CardReportReportService {
  constructor(
    public report: ICardReportForReportService['card'] = defaultCardReport,
    public currentType?: CampaignType,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })

    if (this.currentType === CampaignType.CARD) {
      this.currentType = CampaignType.CARD_FRONT
    }
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report.unique

    return [
      {
        label: 'Sent',
        content: send.count ?? '- -',
        className: 'summary-field--green',
        tooltip: 'Total number of unique campaign sends',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []

    const { delivery, clicks, frontImpressions, backImpressions, deleted } =
      this.report.unique
    return [
      {
        label: 'Delivered',
        content: delivery.count,
        tooltip:
          'Total number of card notifications that were successfully delivered to a device.',
        rateLabel: 'Delivery Rate',
        rate: delivery.rate
      },
      this.currentType === CampaignType.CARD_FRONT && {
        label: 'Front Impressions',
        content: frontImpressions.count,
        tooltip: 'Total number of times users have seen the card.',
        rate: frontImpressions.rate,
        rateLabel: 'Impression Rate'
      },
      this.currentType === CampaignType.CARD_BACK && {
        label: 'Back Impressions',
        content: backImpressions.count,
        tooltip: 'Total number of times users have seen the card.',
        rate: backImpressions.rate,
        rateLabel: 'Impression Rate'
      },
      {
        label: 'Clicks',
        content: clicks.count,
        tooltip: 'Total number of clicks on buttons.',
        rateLabel: 'Click Rate',
        rate: clicks.rate
      },
      {
        label: 'Deleted',
        content: deleted.count ?? '- -',
        rate: deleted.rate,
        rateLabel: 'Deleted Rate'
      }
    ].filter(Boolean) as ISummary[]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    const {
      send,
      delivery,
      frontImpressions,
      frontButtonClickOne,
      frontButtonClickTwo,
      backImpressions,
      backButtonClickOne,
      backButtonClickTwo,
      deleted
    } = this.report.total

    return [
      {
        name: 'Sent',
        valueCurrent: send.count,
        data: send.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Delivered',
        valueCurrent: delivery.count,
        data: delivery.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_FRONT && {
        name: 'Front Impression',
        valueCurrent: frontImpressions.count,
        data: frontImpressions.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_FRONT && {
        name: 'Front Button Click One',
        valueCurrent: frontButtonClickOne.count,
        data: frontButtonClickOne.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_FRONT && {
        name: 'Front Button Click Two',
        valueCurrent: frontButtonClickTwo.count,
        data: frontButtonClickTwo.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_BACK && {
        name: 'Back Impression',
        valueCurrent: backImpressions.count,
        data: backImpressions.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_BACK && {
        name: 'Back Button Click One',
        valueCurrent: backButtonClickOne.count,
        data: backButtonClickOne.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_BACK && {
        name: 'Back Button Click Two',
        valueCurrent: backButtonClickTwo.count,
        data: backButtonClickTwo.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      deleted && {
        name: 'Deleted',
        valueCurrent: deleted.count,
        data: deleted.graph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      }
      // this.currentType === CampaignType.CARD_FRONT &&
      //   front?.frontImpression?.uniqRateGraph?.length && {
      //     name: 'Front Impressions Rate',
      //     valueCurrent: `${
      //       front.frontImpression.total || front.frontImpression.totalUniqRate
      //     }%`,
      //     data: front.frontImpression.uniqRateGraph.slice(),
      //     dataType: ChartDataType.PERCENTAGE
      //   },
      // this.currentType === CampaignType.CARD_BACK &&
      //   back?.backImpression?.uniqRateGraph?.length && {
      //     name: 'Back Impressions Rate',
      //     valueCurrent: `${
      //       back.backImpression.total || back.backImpression.totalUniqRate
      //     }%`,
      //     data: back.backImpression.uniqRateGraph.slice(),
      //     dataType: ChartDataType.PERCENTAGE
      //   }
    ].filter(Boolean) as IChartData[]
  }

  public get goals(): IGoalPerformance[] {
    if (!this.report) {
      return []
    }

    return getGoalsRowsForNotification({
      name: MCampaignTypeToName.get(this.currentType) || '- -',
      goals: this.report.goals
    })
  }

  public get preview(): CardParts | undefined {
    if (!this.notificationPreview) return undefined

    if (this.currentType === CampaignType.CARD_BACK) {
      return this.notificationPreview.card.back
    }
    return this.notificationPreview.card.front
  }
}
