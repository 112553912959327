import { makeAutoObservable } from 'mobx'
import {
  CampaignType,
  CardParts,
  IPreview
} from '~/dataStore/Campaign/Campaign.interface'
import {
  ChartDataType,
  IChartData,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { getGoalsRowsForNotification } from '~/pages/Journeys/Journey.service'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import { ICardReport } from '../../Notification.interface'

export default class CardReport {
  constructor(
    public report: ICardReport,
    public currentType?: CampaignType,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })

    if (this.currentType === CampaignType.CARD) {
      this.currentType = CampaignType.CARD_FRONT
    }
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report

    return [
      {
        label: 'Sent',
        content: send.totalUniq ?? '- -',
        className: 'summary-field--green',
        tooltip: 'Total number of unique campaign sends',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []

    const { delivery, clicks, front, back, delete: deleted } = this.report
    return [
      {
        label: 'Delivered',
        content: delivery.totalUniq,
        tooltip:
          'Total number of card notifications that were successfully delivered to a device.',
        rateLabel: 'Delivery Rate',
        rate: delivery.totalUniqRate
      },
      this.currentType === CampaignType.CARD_FRONT &&
        front.frontImpression && {
          label: 'Front Impressions',
          content: front.frontImpression.totalUniq,
          tooltip: 'Total number of times users have seen the card.',
          rate: front.frontImpression.totalUniqRate,
          rateLabel: 'Impression Rate'
        },
      this.currentType === CampaignType.CARD_BACK &&
        back?.backImpression && {
          label: 'Back Impressions',
          content: back.backImpression.totalUniq,
          tooltip: 'Total number of times users have seen the card.',
          rate: back.backImpression.totalUniqRate,
          rateLabel: 'Impression Rate'
        },
      {
        label: 'Clicks',
        content: clicks.totalUniq,
        tooltip: 'Total number of clicks on buttons.',
        rateLabel: 'Click Rate',
        rate: clicks.totalUniqRate
      },
      {
        label: 'Deleted',
        content: deleted?.totalUniq ?? '- -',
        rate: deleted?.totalUniqRate,
        rateLabel: 'Deleted Rate'
      }
    ].filter(Boolean) as ISummary[]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    const { send, delivery, front, back, delete: deleted } = this.report

    return [
      {
        name: 'Sent',
        valueCurrent: send.totalUniq,
        data: send.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Delivered',
        valueCurrent: delivery.totalUniq,
        data: delivery.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_FRONT &&
        front.frontImpression && {
          name: 'Front Impression',
          valueCurrent: front.frontImpression.totalUniq,
          data: front.frontImpression.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_FRONT &&
        front.frontButtonClickOne && {
          name: 'Front Button Click One',
          valueCurrent: front.frontButtonClickOne.totalUniq,
          data: front.frontButtonClickOne.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_FRONT &&
        front.frontButtonClickTwo && {
          name: 'Front Button Click Two',
          valueCurrent: front.frontButtonClickTwo.totalUniq,
          data: front.frontButtonClickTwo.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_FRONT &&
        front.frontButtonClickReply && {
          name: 'Front Button Click Reply',
          valueCurrent: front.frontButtonClickReply.totalUniq,
          data: front.frontButtonClickReply.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_FRONT &&
        front.frontButtonClick && {
          name: 'Total Front Clicks',
          valueCurrent: front.frontButtonClick.totalUniq,
          data: front.frontButtonClick.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_BACK &&
        back?.backImpression && {
          name: 'Back Impression',
          valueCurrent: back.backImpression.totalUniq,
          data: back.backImpression.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_BACK &&
        back?.backButtonClickOne && {
          name: 'Back Button Click One',
          valueCurrent: back.backButtonClickOne.totalUniq,
          data: back.backButtonClickOne.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_BACK &&
        back?.backButtonClickTwo && {
          name: 'Back Button Click Two',
          valueCurrent: back.backButtonClickTwo.totalUniq,
          data: back.backButtonClickTwo.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_BACK &&
        back?.backButtonClickReply && {
          name: 'Back Button Click Reply',
          valueCurrent: back.backButtonClickReply.totalUniq,
          data: back.backButtonClickReply.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      this.currentType === CampaignType.CARD_BACK &&
        back?.backButtonClick && {
          name: 'Total Back Clicks',
          valueCurrent: back.backButtonClick.totalUniq,
          data: back.backButtonClick.uniqGraph?.slice() || [],
          dataType: ChartDataType.NUMERIC
        },
      deleted && {
        name: 'Deleted',
        valueCurrent: deleted.totalUniq,
        data: deleted.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      this.currentType === CampaignType.CARD_FRONT &&
        front?.frontImpression?.uniqRateGraph?.length && {
          name: 'Front Impressions Rate',
          valueCurrent: `${front.frontImpression.totalUniqRate}%`,
          data: front.frontImpression.uniqRateGraph.slice(),
          dataType: ChartDataType.PERCENTAGE
        },
      this.currentType === CampaignType.CARD_BACK &&
        back?.backImpression?.uniqRateGraph?.length && {
          name: 'Back Impressions Rate',
          valueCurrent: `${back.backImpression.totalUniqRate}%`,
          data: back.backImpression.uniqRateGraph.slice(),
          dataType: ChartDataType.PERCENTAGE
        }
    ].filter(Boolean) as IChartData[]
  }

  public get goals(): IGoalPerformance[] {
    if (!this.report) {
      return []
    }

    return getGoalsRowsForNotification({
      name: MCampaignTypeToName.get(this.currentType) || '- -',
      goals: this.report.goals
    })
  }

  public get preview(): CardParts | undefined {
    if (!this.notificationPreview) return undefined

    if (this.currentType === CampaignType.CARD_BACK) {
      return this.notificationPreview.card.back
    }
    return this.notificationPreview.card.front
  }
}
