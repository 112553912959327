import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { fetchAppDetails } from '~/api/apps'
import { fetchCurrentAdmin } from '~/api/admins'

import permissions from '~/utils/permissions'
import { IAppDetails, ICurrentAdmin, ICurrentApp } from './App.interface'
import { ADMIN_ROLES } from '~/common.interface'

declare global {
  interface Window {
    PulsateSDK: any
    pendo: any
  }
}

export default class App {
  // App curtain
  loaded = 1

  unlayerAccessKey = ''

  googleMapsApiKey = ''

  // Current admin
  currentAdmin: ICurrentAdmin = {
    username: '',
    avatarUrl: '',
    email: '',
    adminAccessToken: '',
    isDefaultAvatar: false,
    isDemoAdmin: false,
    jobTitle: null,
    companies: [],
    name: '',
    recentMobileAppId: '',
    role: undefined,
    id: ''
  }

  // Current App
  currentApp: ICurrentApp = {
    id: '',
    type: 'app'
  }

  appDetails: IAppDetails = {
    addLocationParamsToPushUrls: false,
    company: '',
    icon: '',
    id: '',
    image: '',
    installed: false,
    isDefaultImage: false,
    masterCurrency: '',
    name: '',
    pushNotificationTtl: '',
    rangeDays: [],
    oeRangeInDays: [],
    verifiedEmailAddresses: [],
    beacon: {
      eventUrl: '',
      eventWebhookEnabled: false
    },
    geofenceSetting: {
      geofenceFeetUnit: 'true',
      maxRadius: 6564,
      minRadius: 31
    },
    featureFlags: {
      adminAccessRestrictions: false, // used to allow changing admin access permissions on admins list
      card: false, // controls access to card related modules
      email: false, // controls access to email related modules
      journeys: false, // controls access to journeys module
      externalGoalFileUploadSection: false, // controls access to upload csv files with integrationGoal on channelsettings page
      polygon: false, // controls access to drawing polygons in geofences module
      sms: false, // controls access to sms related modules
      pushNotificationsFullAccess: true, // allows to use all push notification features(title, subtitle, richMedia, action buttons)
      geofences: true,
      beacons: true,
      notificationsExtraButtons: false, // controls access to more than one btn on inapp and card notifications
      csvUsersUpload: false, // controls access to upload users by csv file on users list
      changeNotificationButtonColors: false, // controls ability to change color of text and background of buttons in notifications
      q2InApp: false, // when true switch the order of buttons in inapp notifications, primary on bottom, secondary on top
      campaignsGoals: false, // controls visibility of goals and control group sections in campaigns
      messages: false, // controls access to messages module
      dashboardBeta: false, // controls access to dashboard beta
      webSdk: false,
      coreMemberIdUpload: false, // Used for displaying Core Member ID instead of User GUID (alias)
      pushRichMedia: true, // controls access to rich media in push notifications
      push: true, // controls access to push builder
      smallInApp: true, // controls access to small in-app builder
      feedPostPlusNotification: true, // controls access to feed post plus notification option in builder
      demoMode: false, // if demo mode is on the cms will not send notifications, just a preview of builder
      oldAppAndDeviceStats: true, // controls access to old app and device stats
      bannoPushSendToUrl: false, // controls access to alert notification for bano
      opportunitiesDashboard: false // controls access to opportunities dashboard
    },
    fcm: {
      serverKey: '',
      senderId: '',
      fcmFileIdentifier: ''
    },
    apns: {
      password: '',
      bundleId: '',
      certificate: {
        identifier: '',
        expired: false,
        aboutToExpire: false,
        expiredAt: '',
        errors: null
      }
    },
    campaignGlobalSettings: {
      timeValue: null,
      timeFrame: 'minutes',
      waitValue: null,
      waitFrame: 'minutes',
      silentPush: false,
      sendValue: null
    },
    downloadOpportunityFiles: {
      appFile: false,
      performanceFiles: false
    }
  }

  constructor() {
    makeObservable(this, {
      loaded: observable,
      currentAdmin: observable,
      currentApp: observable,
      appDetails: observable,
      permissions: computed,
      openCurtain: action.bound,
      closeCurtain: action.bound,
      setCurrentAdmin: action.bound,
      getSessionData: action.bound,
      getAppDetails: action.bound,
      setAppDetails: action.bound
    })
  }

  public get permissions(): string[] {
    if (this.currentAdmin.role === undefined) return []
    return permissions[this.currentAdmin.role] || []
  }

  openCurtain(): void {
    if (this.loaded > 0) {
      this.loaded -= 1
    }
  }

  closeCurtain(): void {
    this.loaded += 1
  }

  public setCurrentAdmin(data: Partial<ICurrentAdmin>): void {
    this.currentAdmin = {
      ...this.currentAdmin,
      ...data
    }
  }

  async getAppDetails(appId: string): Promise<IAppDetails> {
    const response: IAppDetails = await fetchAppDetails(appId)

    runInAction(() => {
      this.appDetails = response
    })

    if (this.appDetails.featureFlags.webSdk) {
      window.PulsateSDK.init({
        alias: this.currentAdmin.id,
        guid: this.currentAdmin.id,
        firstName: this.currentAdmin.name,
        lastName: this.currentAdmin.role,
        email: this.currentAdmin.email
      })
    }

    return response
  }

  async getSessionData(): Promise<ICurrentAdmin> {
    const response = await fetchCurrentAdmin()

    runInAction(() => {
      this.currentAdmin = response.admin

      this.currentAdmin.companies = response.companies
      this.currentAdmin.isDemoAdmin =
        response.admin.role === ADMIN_ROLES.DEMO_ADMIN
      this.unlayerAccessKey = response.unlayerAccessKey
      this.googleMapsApiKey = response.googleApiKey
      this.currentApp = response.currentApp
    })

    return response.admin
  }

  public setAppDetails(data: Partial<IAppDetails>): void {
    this.appDetails = {
      ...this.appDetails,
      ...data
    }
  }
}
