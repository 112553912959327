import React from 'react'
import icon from './icon.svg'
import styles from './NextBestOpportunitiesEmpty.scss'

const NextBestOpportunitiesEmpty = () => {
  return (
    <div className={styles.wrapper}>
      <div className="w-75 mx-auto text-center">
        <div className={styles.icon}>
          <img src={icon} alt="icon" />
        </div>
        <p className="mt-3 fw-medium text-base">
          No missed opportunities here! Connect your data, and <br /> we’ll
          surface the best ways to engage and grow.
        </p>
      </div>
    </div>
  )
}

export default NextBestOpportunitiesEmpty
