import React from 'react'
import { Button } from 'reactstrap'
import Link from '~/components/Link'
import routes from '~/routes'

import styles from './NoDataOverlay.scss'
import { useStore, withStore } from '~/dataStore'

interface IProps {
  icon: string
  text: string
  startCampaignBtn?: boolean
}

const NoDataOverlay = ({ icon, text, startCampaignBtn }: IProps) => {
  const {
    app: {
      appDetails: { id: appId }
    }
  } = useStore()

  return (
    <div className={styles.noDataOverlay}>
      <div className={styles.noDataOverlayContainer}>
        <div className={styles.noDataOverlayIcon}>
          <img src={icon} alt="" />
        </div>
        <p className="fw-medium lh-base text-base text-center mt-3">{text}</p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {startCampaignBtn && (
          <Link
            route={appId ? routes.newCampaign : '/'}
            params={{ appId }}
            className="align-self-center d-flex">
            <Button
              color=""
              className="border rounded-lg text-black shadow-md border-mine-shaft px-3 no-wrap text-base mt-2 bg-white">
              Start Campaign
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}

export default withStore(NoDataOverlay)
