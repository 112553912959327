import { ID } from '~/common.interface'
import { IntegrationFilesDTO } from '~/pages/App/ChannelSettings/sections/IntegrationGoalFiles/IntegrationGoalFiles.interface'
import {
  ICurrenciesDTO,
  IRevenueEventsDTO,
  RevenueFormValues
} from '~/pages/App/ChannelSettings/sections/Revenue/RevenueSection.interface'
import { CampaignGlobalSettingsPayload } from '~/pages/App/Settings/sections/GlobalSettingsSection/GlobalSettings.interface'
import { IWebSDKCredentials } from '~/pages/App/Settings/sections/WebSDKSection/WebSDKSection.interface'
import { destroy, get, post, put, upload } from './v2'
import { get as getV3, upload as uploadV3 } from './v3'
import { FormValues } from '~/pages/App/Settings/sections/BannoSettings/BannoSettingsSection.interface'

export const resendEmailVerification = (appid: ID, emailId: ID) => {
  return put(
    `${appid}/setting/email_addresses/${emailId}/resend_verification_email`
  )
}

export function fetchEmailAddresses(
  appId: string
): Promise<{ data: string[] }> {
  return get(`${appId}/setting/email_addresses`)
}

export function addEmailAddressToVerify(
  appId: string,
  email: string
): Promise<{ emailAddress: { id: ID; email: string; verified: boolean } }> {
  return post(`${appId}/setting/email_addresses`, { email })
}

export function removeEmailAddress(appId: ID, emailId: ID): Promise<void> {
  return destroy(`${appId}/setting/email_addresses/${emailId}`)
}

export function getFromNames(
  appId: string
): Promise<{ id: ID; name: string }[]> {
  return get(`${appId}/setting/email_from_names`)
}

export function removeFromName(appId: string, fromNameId: ID): Promise<void> {
  return destroy(`${appId}/setting/email_from_names/${fromNameId}`)
}

export function addFromName(
  appId: string,
  name: string
): Promise<{ emailFromName: { id: ID; name: string } }> {
  return post(`${appId}/setting/email_from_names`, { name })
}

export function updatePushSettings(
  appId: string,
  data: {
    settingAttributes: {
      pushNotificationTtl: number | string
      addLocationParamsToPushUrls: boolean
    }
  }
): Promise<void> {
  return put(`${appId}/push_notification_settings`, data)
}

export function updateGeofenceMetricSettings(
  appId: string,
  data: {
    geofenceFeetUnit: 'true' | 'false'
  }
): Promise<{
  geofenceFeetUnit: 'true' | 'false'
  minRadius: number
  maxRadius: number
}> {
  return put(`${appId}/geofence_unit_settings`, data)
}

export function fetchRevenueEvents(appId: string): Promise<IRevenueEventsDTO> {
  return get(`${appId}/average_revenues`)
}

export function addRevenueEvent(
  appId: string,
  data: RevenueFormValues
): Promise<RevenueFormValues> {
  return post(`${appId}/average_revenues`, data)
}

export function editRevenueEvent(
  appId: ID,
  data: RevenueFormValues,
  id: ID
): Promise<void> {
  return put(`${appId}/average_revenues/${id}`, data)
}

export function removeRevenueEvent(appId: string, id: ID): Promise<void> {
  return destroy(`${appId}/average_revenues/${id}`)
}

export function editMasterCurrency(
  appId: string,
  data: { masterCurrency: string }
): Promise<void> {
  return put(`${appId}/master_currency`, data)
}

export function fetchCurrencies(appId: string): Promise<ICurrenciesDTO> {
  return get(`${appId}/currencies`)
}

export function updateGlobalCampaignSettings(
  appId: string,
  data: CampaignGlobalSettingsPayload
): Promise<void> {
  return put(`${appId}/global_notification_settings`, data)
}

export function updateFCMV1Settings(
  appId: string,
  data: FormData
): Promise<void> {
  return uploadV3(`${appId}/fcm`, data, 'PUT')
}

export function fetchSDKSettings(
  appId: string
): Promise<{ appId: string; appKey: string; accessToken: string }> {
  return get(`${appId}/sdk_credentials`)
}

export function editWebhookSettings(
  appId: string,
  data: {
    settingAttributes: {
      beaconEventWebhookEnabled: boolean
      beaconEventUrl: string
    }
  }
): Promise<void> {
  return put(`${appId}/webhook`, data)
}

export function updateAPNSSettings(
  appId: string,
  data: FormData
): Promise<void> {
  return upload(`${appId}/apns_pem`, data, 'PUT')
}

export function updateAPNSSettingsP8(
  appId: string,
  data: FormData
): Promise<void> {
  return upload(`${appId}/apns_p8`, data, 'PUT')
}

export function updateApplicationSettings(
  appId: string,
  data: { name: string; imageS3Url?: string; reset: string }
): Promise<{ image: string; icon: string }> {
  return put(`${appId}/app_configuration`, data)
}

export function fetchIntegrationGoalFiles(
  appId: ID
): Promise<IntegrationFilesDTO> {
  return getV3(`${appId}/attachments/uploaded_integrationable_csv`)
}

export function uploadIntegrationGoalFile(
  appId: ID,
  file: File
): Promise<void> {
  const data = new FormData()
  data.append('file', file, file.name)
  return uploadV3(`${appId}/attachments/upload_integrationable_csv`, data)
}

export function fetchSDKCredentials(appId: ID): Promise<IWebSDKCredentials> {
  return get(`${appId}/web_sdk_credentials`)
}

export function createWebSDKDomain(
  appId: ID,
  data: { url: string }
): Promise<void> {
  return post(`${appId}/web_sdk_domains`, data)
}
export function editWebSDKDomain(
  appId: ID,
  id: ID,
  data: { url: string }
): Promise<void> {
  return put(`${appId}/web_sdk_domains/${id}`, data)
}
export function removeWebSDKDomain(appId: ID, id: ID): Promise<void> {
  return destroy(`${appId}/web_sdk_domains/${id}`)
}

export function generateWebSDKKey(appId: ID): Promise<void> {
  return post(`${appId}/create_web_sdk_key`)
}

export function updateBannoSettings(
  appId: ID,
  data: FormValues
): Promise<FormValues> {
  return post(`${appId}/upsert_banno_config`, data)
}

export function upsertBannoPem(appId: ID): Promise<{ publicKey: string }> {
  return post(`${appId}/upsert_banno_pem`)
}
